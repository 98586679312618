import type { PageProps } from 'components/App';
import ContentPage from 'components/ContentPage';
import FullScreenLoadingSpinner from 'components/FullScreenLoadingSpinner';
import useContentPage from 'hooks/useContentPage';
import { getLocaleFromPath } from 'lib/locale/locale';
import { NOTFOUND_SLUG } from 'models/contentPages/types';
import { GetStaticPaths, GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import { FC } from 'react';

const NotFound: FC<PageProps> = ({ footer, siteSettings }) => {
  const { asPath } = useRouter();

  const locale = getLocaleFromPath(asPath, 'en-us');

  const { data: notFoundPage, loading } = useContentPage({
    locale,
    slug: NOTFOUND_SLUG,
  });

  return (
    <>
      {!loading && notFoundPage ? (
        <ContentPage
          locale={locale}
          footer={footer}
          siteSettings={siteSettings}
          {...notFoundPage}
        />
      ) : (
        <FullScreenLoadingSpinner message="Page not found" />
      )}
    </>
  );
};

export default NotFound;

export const getStaticProps: GetStaticProps = () => {
  return {
    props: {},
    revalidate: 60 * 10,
  };
};

export const getStaticPaths: GetStaticPaths = () => {
  return { paths: ['en-us/404'], fallback: false };
};
