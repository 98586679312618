import { ContentPageModel } from 'models/contentPages/types';
import { useEffect, useState } from 'react';

type UseContentPage = (args: { slug: string; locale: string }) => {
  data: ContentPageModel | null;
  error: Error | null;
  loading: boolean;
};

const useContentPage: UseContentPage = ({ slug, locale }) => {
  const [data, setData] = useState<ContentPageModel | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchPage = async () => {
      setData(null);
      setLoading(true);
      setError(null);

      try {
        const resource = await fetch(
          `/services/content/fetch-content-page?type=contentPage&slug=${slug}&locale=${locale}&limit=1`
        );

        const content = await resource.json();

        setData(content);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError(e as Error);
      }
    };

    fetchPage();
  }, [slug, locale]);

  return { data, loading, error };
};

export default useContentPage;
