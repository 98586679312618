import FullScreenOverlay from 'components/FullScreenOverlay';
import Gap from 'components/Gap';
import { Column, Grid } from 'components/Grid';
import LoadingSpinner from 'components/LoadingSpinner';
import { Statement } from 'components/Typography';
import { FC } from 'react';

export interface FullScreenLoadingSpinnerProps {
  message?: string;
}

const FullScreenLoadingSpinner: FC<FullScreenLoadingSpinnerProps> = ({
  message,
}) => (
  <FullScreenOverlay>
    <LoadingSpinner loading={true} />
    {message ? (
      <>
        <Gap size={{ xs: 16, m: 32 }} />
        <Grid columns={{ xs: 6, l: 12 }}>
          <Column starts={{ xs: 2, l: 5 }} spans={4}>
            <Statement
              textColor="grey6"
              variant="1"
              size={{ xs: 'XS', m: 'S' }}
            >
              {message}
            </Statement>
          </Column>
        </Grid>
      </>
    ) : null}
  </FullScreenOverlay>
);

export default FullScreenLoadingSpinner;
