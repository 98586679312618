import { Spinner } from 'components/Icons';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface ModalSpinnerProps {
  className?: string;
  loading: boolean;
  dark?: boolean;
  children?: ReactNode;
}

const SpinnerContainer = styled.div`
  display: flex;
  align-self: center;
  justify-self: center;
`;

const LoadingSpinner: FC<ModalSpinnerProps> = ({
  className,
  children,
  dark,
  loading,
}) =>
  loading === true ? (
    <SpinnerContainer className={className}>
      <Spinner strokeColor={dark ? 'black' : 'white'} />
    </SpinnerContainer>
  ) : (
    <>{children}</>
  );

export default LoadingSpinner;
