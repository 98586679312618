import { Spinner } from 'components/Icons';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface ModalSpinnerProps {
  loading: boolean;
  dark?: boolean;
  children?: ReactNode;
}

const Wrapper = styled.div`
  position: relative;
`;

const SpinnerContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
`;

const ModalSpinner: FC<ModalSpinnerProps> = ({ children, dark, loading }) => (
  <Wrapper>
    {children}
    {loading ? (
      <SpinnerContainer>
        <Spinner strokeColor={dark ? 'black' : 'white'} />
      </SpinnerContainer>
    ) : null}
  </Wrapper>
);

export default ModalSpinner;
